import {
  gameConfig,
  pathsConfig,
  opponentConfig,
  lungeConfig,
  cameraConfig
} from '@/app/config'
import type { SpeedManager } from '@/app/SpeedManager/SpeedManager'
import {
  THREE,
  TimesTypes,
  cameraManager,
  game,
  modes,
  timeManager
} from '@powerplay/core-minigames'
import { linesManager } from './LinesManager'
import { disciplinePhasesManager } from '@/app/phases/DisciplinePhasesManager'
import { Athlete } from '../athlete'
import type { Opponent } from '../athlete/opponent/Opponent'
import { tutorialFlow } from '@/app/modes/tutorial/TutorialFlow'
import { TutorialEventType } from '@/app/types'
import { audioHelper } from '@/app/audioHelper/AudioHelper'
import { DisciplinePhases } from '@/app/types'
import { worldEnv } from './WorldEnv'
import {
  textMessageState,
  uiState
} from '@/stores'

/**
 * Metoda na vytvorenie ciary s ktorou sa neskor bude manipulovat na jazdenie
 */
export class WorldEnvLinesCreator {

  /** Object 3d na zmenu jazdy a smeru */
  private object3d = new THREE.Object3D()

  /** Percento kde je aktualne hrac */
  private actualPercent = 0

  /** Percento kde bol naposledy hrac */
  private lastPercent = 0

  /** Percento kde bol v cieli hrac */
  private finishPercent = 0

  /** Kolko percent je 1 meter na krivke */
  public oneMeterInPercent = 0

  /** Offset pri idealnu liniu */
  public idealLineOffset = 0

  /** Posledny offset od idealu v zakrute */
  public lastOffsetFromIdealInTurn = 0

  /** trat hraca */
  public playerPath?: THREE.CurvePath<THREE.Vector3>

  /** aktivna cesta hraca */
  public finishPhaseStarted = false

  /** Kolko m ma trat */
  public totalPathLength = 0

  /** Index drahy, v ktorej zacina hrac */
  public pathIndex = 0

  /** Aktualny offset pre lunge */
  public lungeOffset = 0

  /** kolo */
  public lap = 0

  /** raycast z hraca */
  private playerRaycast = new THREE.Raycaster()

  /** percento pozicie v starej drahe */
  private oldActualPercent = 0

  /** stara draha z ktorej prechadzame */
  private oldPlayerPath?: THREE.CurvePath<THREE.Vector3>

  /** vektor na ktory sa object3d pozera */
  private lookingAt = new THREE.Vector3()

  /** vektor osi Y pre vypocty */
  private axisY = new THREE.Vector3(0, 1, 0 )

  /** pomocny vektor */
  private helperVector = new THREE.Vector3()

  /** pomocny vektor pre direction */
  private helperVectorDirection = new THREE.Vector3()

  /** Ci uz bolo poriesene audio pre predposledne kolo */
  private playedAudioPreFinalLap = false

  /** Ci uz bolo poriesene audio pre posledne kolo */
  private playedAudioFinalLap = false

  /** Dolly zoom default fov */
  private dollyZoomDefaultFov?: number

  /** Dolly zoom default z pos */
  private fovLerp = new THREE.Vector3(0, 0, 0)

  /** Pomocny vektore pre foc */
  private fovVector = new THREE.Vector3(0, 0, 0)

  /** Posledna hodnota pre lerp, kvoli performance */
  private lastFovLerpValue = 0

  /** Aktualny idealny pomocny offset */
  public actualIdealOffset = new THREE.Vector3(0, 0, 0)

  /** pocitadlo framov prechodu drahy */
  public changePathTransitionFrames = 0

  /** vsetci atletov */
  public allAthletes: Athlete[] = []

  /** ci sa ma naklanat */
  public leanOnActive = false

  /** ktora hlaska togo bola */
  public togoTypeLast = -1

  /** v kolkom percente aktualnej sekcie */
  public percentOfActualSection = 0

  /** index aktualnej sekcie */
  public actualSectionIndex = 0

  /**
   * Konstruktor
   * @param pathIndex - index pre trat
   * @param animationsManager - manager pre animaciu
   */
  public constructor(
    pathIndex: number,
    private callbackFinish: (actualPos: number, lastPos: number, finishPos: number) => unknown,
    private athlete: Athlete
  ) {

    this.playerPath = linesManager.getPath(pathIndex)
    this.pathIndex = pathIndex

    this.setActualPercentOnStartIntro()
    this.callbackFinish = callbackFinish
    this.calculateLineInfo()
    this.setupObject3d()

    this.actualIdealOffset = gameConfig.cameraConfig.idealOffset.clone()

  }

  /**
   * Setter
   * @param allAthletes - vsetci
   */
  public setAllAthletes(allAthletes: Athlete[]): void {

    this.allAthletes = allAthletes

  }

  /**
   * Instantna zmena drahy
   * @param index - index drahy
   */
  public changePathTo(index: number): void {

    this.pathIndex = index
    this.playerPath = linesManager.getPath(index)
    this.calculateLineInfo()

  }

  /**
   * Prechod do inej drahy podla indexu
   * @param pathIndex - index novej drahy
   * @returns true ak sa podarila zmena
   */
  public changePath(pathIndex: number): boolean {

    const newPath = linesManager.getPath(pathIndex)
    if (newPath === undefined) return false

    if (!this.calculateChange(pathIndex)) return false

    this.oldPlayerPath = this.playerPath
    this.playerPath = newPath

    this.pathIndex = pathIndex
    this.calculateLineInfo()

    return true

  }

  /**
   * Vypocet a nastavenie noveho actualPercent pri presune do vedlajsej drahy
   * @param pathIndex - pathIndex noveh drahy
   * @param doChange - ci sa ma aj vykonat presun, alebo iba checky
   * @returns true, ak sa podarilo najst miesto v novej drahe
   */
  public calculateChange(pathIndex: number, doChange = true): boolean {

    if (this.playerPath === undefined) return false

    const toLeft = this.pathIndex < pathIndex

    const lookAt = this.lookingAt.clone()
    this.helperVector.set(0, 0, 0)
    const playerPositionOnPath = this.playerPath.getPointAt(this.actualPercent)
    if (!playerPositionOnPath) return false
    // playerPositionOnPath.y += this.getYCorrection()

    // lookAt.setY(playerPositionOnPath.y)
    this.helperVector.subVectors(lookAt, playerPositionOnPath).normalize()

    let angle = Math.PI / 2
    if (!toLeft) angle *= -1

    this.helperVector.applyAxisAngle(this.axisY, angle)

    this.playerRaycast.set(playerPositionOnPath, this.helperVector)

    // const debugArrow = new THREE.ArrowHelper(
    //   this.playerRaycast.ray.direction,
    //   this.playerRaycast.ray.origin,
    //   2,
    //   Math.random() * 0xffffff
    // )
    // game.scene.add(debugArrow)


    const indexDiff = toLeft ? 1 : -1
    const trackNumber = this.pathIndex + indexDiff

    // zoberiem si bod, kde sa pretal s krivkou kde chcem ist
    const intersectionPoint = this.playerRaycast
      .intersectObject(game.getObject3D(`TrackLine_${trackNumber}`))?.[0]?.point

    if (intersectionPoint === undefined) return false
    intersectionPoint.setY(0)

    // zoberiem si z tej krivky x bodov pred aktualnym percentom a x bodov po aktualnom percente
    const pointsToSearch = this.getPointsToSearch(this.pathIndex + indexDiff)

    // prejdem tento zoznam a najdem najblizsi bod,
    let distance: number | undefined = undefined
    let lowestDistancePointIndex = 0
    pointsToSearch.forEach((point, index) => {

      const newDistance = point.distanceTo(intersectionPoint)
      if (distance === undefined || newDistance < distance) {

        distance = newDistance
        lowestDistancePointIndex = index

      }

    })
    const { percentDiff, pointsPerPercent } = pathsConfig.changePathConfig
    const newPercent = this.actualPercent +
            (lowestDistancePointIndex / pointsPerPercent - percentDiff)

    const isOpponentBlocking = this.isOpponentBlockingChange(pathIndex, newPercent)
    if (isOpponentBlocking) return false
    if (!doChange) return !isOpponentBlocking

    // podla indexu v zozname bodov urcim nove percento
    this.oldActualPercent = this.actualPercent
    this.actualPercent = newPercent
    this.setupObject3d()
    // optional improvement: prepocitat podla oneMeter konstanty danej krivky

    this.changePathTransitionFrames = pathsConfig.changePathConfig.framesToChange

    return true

  }

  /**
   * Zistime, ci niekto blokuje drahu do ktorej chceme ist
   */
  private isOpponentBlockingChange(pathIndex: number, newPercent: number): boolean {

    const { blockingOffset } = pathsConfig.changePathConfig
    const opponentsPercentInDesiredPath = this.allAthletes.filter(opponent => {

      return opponent.worldEnvLinesManager.pathIndex === pathIndex

    }).filter(opponent => {

      const blockingOffsetInPercent = blockingOffset * this.oneMeterInPercent
      return opponent.worldEnvLinesManager.actualPercent < newPercent + blockingOffsetInPercent &&
                opponent.worldEnvLinesManager.actualPercent > newPercent - blockingOffsetInPercent

    })

    return opponentsPercentInDesiredPath.length > 0

  }

  /**
   * Vyberie body vyseku drahy s frekvenciou podla configu
   * @param newTrackId - id novej trate
   * @returns pointsToSearch - body na vyseku drahy
   */
  private getPointsToSearch(newTrackId: number): THREE.Vector3[] {

    const newPath = linesManager.getPath(newTrackId)
    const pointsToSearch: THREE.Vector3[] = []
    const { percentDiff, pointsPerPercent } = pathsConfig.changePathConfig

    for (let i = 0; i < percentDiff * 2 * pointsPerPercent; i++) {

      const point = newPath?.getPointAt(this.actualPercent + (i / pointsPerPercent - percentDiff))
      if (point) {

        point.setY(0)
        pointsToSearch.push(point)

      } else {

        pointsToSearch.push(new THREE.Vector3())

      }

    }
    return pointsToSearch

  }

  /**
   * Hodnota pre korekciu Y pozicie
   * @returns yCorrection
   */
  private getYCorrection(): number {

    let correction = gameConfig.yPlayerCorrection
    // if (this.athlete.isEnd) return gameConfig.yPlayerCorrectionSnow
    if (!this.athlete.playable) {

      const playerPercent = this.athlete.worldEnvLinesManager.actualPercent
      const correctionDistancePercent =
                opponentConfig.yPossitionCorrectionDistance * this.oneMeterInPercent
      if (
        this.actualPercent > playerPercent + correctionDistancePercent ||
                this.actualPercent < playerPercent - correctionDistancePercent
      ) {

        correction = opponentConfig.yOpponentFarPosition
        return correction

      }

    }

    return correction

  }

  /**
   * Vratenie aktualnych percent na trati
   * @returns hodnota % na trati
   */
  public getActualPercent(): number {

    return this.actualPercent

  }

  /**
   * Vratenie poslednych percent na trati
   * @returns hodnota % na trati
   */
  public getLastPercent(): number {

    return this.lastPercent

  }

  /**
   * Vratenie cielovych percent na trati
   * @returns hodnota % na trati
   */
  public getFinishPercent(): number {

    return this.finishPercent

  }

  /**
   * Nastavenie pociatocnej pozicie pre intro
   */
  private setActualPercentOnStartIntro(): void {

    this.actualPercent = pathsConfig.positionStartIntro

  }

  /**
   * Nastavenie pociatocnej pozicie pre start
   */
  public setActualPercentOnStart(): void {

    this.actualPercent = pathsConfig.positionStart +
     (this.athlete.startingOrder * pathsConfig.startGap)

    // ked potrebujeme skip do ciela
    if (gameConfig.skipToFinish.active) this.actualPercent = gameConfig.skipToFinish.percent

    this.setupObject3d()

  }

  /**
   * Skontrolovanie, ci sa ma dat finish alebo nie
   */
  private checkFinish(): void {

    if (!this.isFinishEnabled() || this.finishPhaseStarted) return

    this.callbackFinish(
      this.actualPercent * this.totalPathLength,
      this.lastPercent * this.totalPathLength,
      (pathsConfig.positionsFinish[this.pathIndex - 1] - this.lungeOffset) *
      this.totalPathLength
    )

    this.finishPercent = this.actualPercent
    this.finishPhaseStarted = true

  }

  /**
   * Skontrolovanie, ci je 200m pred cielom
   */
  private checkBeforeFinish(): void {

    if (!this.athlete.playable) {

      const opponent = this.athlete as Opponent
      const specialDiff = opponent.last200mSpecial - 200
      if (
        this.lap === gameConfig.numberOfLaps &&
        this.actualPercent + this.lungeOffset >=
         pathsConfig.startTimePercent[this.pathIndex - 1] - (specialDiff * this.oneMeterInPercent)
      ) {

        opponent.last200m = true

      }

    }

    if (!this.playedAudioPreFinalLap && this.lap === gameConfig.numberOfLaps - 1) {

      audioHelper.playAudioPreFinalLap(this.athlete.playable)
      this.playedAudioPreFinalLap = true

    }

    if (!this.playedAudioFinalLap && this.lap === gameConfig.numberOfLaps) {

      audioHelper.playAudioFinalLap(this.athlete.playable)
      this.playedAudioFinalLap = true

    }

    if (
      this.athlete.playable &&
      this.lap === gameConfig.numberOfLaps &&
      this.actualPercent + this.lungeOffset >=
        pathsConfig.last100m[this.pathIndex - 1] &&
      this.togoTypeLast < 1
    ) {

      textMessageState().$patch({
        showTogo: true,
        showType: 1
      })
      worldEnv.setVisibilityFinishWall(true)
      this.togoTypeLast = 1

    }
    if (
      this.lap === gameConfig.numberOfLaps &&
      this.actualPercent + this.lungeOffset >=
      pathsConfig.startTimePercent[this.pathIndex - 1] &&
      !this.athlete.last200m
    ) {

      if (this.athlete.playable && !this.athlete.last200m && this.togoTypeLast < 0) {

        this.athlete.last200m = true
        tutorialFlow.eventActionTrigger(TutorialEventType.last200m)

        textMessageState().$patch({
          showFinalLap: false,
          showTogo: true,
          showType: 0
        })
        this.togoTypeLast = 0

      }

      if (timeManager.getActive(TimesTypes.game)) return
      timeManager.setActive(TimesTypes.game, true)

      uiState().$patch({
        showTimeKeeper: true,
        showFinishTopBox: false,
        showTrainingLayout: modes.isTrainingMode(),
        isTraining: modes.isTrainingMode()
      })

    }

  }

  /**
   * Skontrolovanie, ci uz nie je koniec trate
   */
  private checkEndOfPath(): void {

    if (this.actualPercent >= 1) {

      this.actualPercent -= 1// this.callbackEndOfPath()
      this.lap += 1

      if (gameConfig.debugLastLap && this.lap < 3) {

        this.lap = 3

      }

      if (this.athlete.playable && this.lap === gameConfig.numberOfLaps) {

        textMessageState().$patch({
          showCountdown: false,
          showFinalLap: true
        })

      }

    }

  }

  /**
   * Update funkcia
   * @param speedManaager - Speed manager
   * @param inTop3 - Ci je hrac v top 3
   * @returns - Novy object 3D
   */
  public update(speedManager: SpeedManager): THREE.Object3D {

    if (speedManager.isActive()) {

      // rychlost v m/frame
      const actualSpeed = speedManager.getActualSpeedPerFrame()
      const actualPercentSpeed = this.oneMeterInPercent * actualSpeed

      // musime si zapamatat posledne percento
      this.lastPercent = this.actualPercent

      // pridavame aktualnu rychlost v %/frame
      this.actualPercent += actualPercentSpeed
      this.oldActualPercent += actualPercentSpeed

      this.dollyZoom()

      // kontrola veci - triggerov
      this.checkFinish()
      this.checkEndOfPath()
      this.setPercentOfActualSection()
      this.checkBeforeFinish()
      this.checkLeanOn()

      this.setupObject3d()

    }

    return this.object3d

  }

  /**
   * Nastavenie percentOfActualSection
   */
  private setPercentOfActualSection(): void {

    const { sections } = pathsConfig
    if (this.actualPercent > sections[3][this.pathIndex - 1]) {

      this.actualSectionIndex = 3

    } else if (this.actualPercent > sections[2][this.pathIndex - 1]) {

      this.actualSectionIndex = 2

    } else if (this.actualPercent > sections[1][this.pathIndex - 1]) {

      this.actualSectionIndex = 1

    } else {

      this.actualSectionIndex = 0

    }

    const sectionEnd = sections?.[this.actualSectionIndex + 1]?.[this.pathIndex - 1] || 1
    this.percentOfActualSection = ((this.actualPercent - sections[this.actualSectionIndex][this.pathIndex - 1]) * 100) /
     (sectionEnd - sections[this.actualSectionIndex][this.pathIndex - 1])

  }

  /**
   * Nastavenie dolly zoomu podla pozicie hraca na trati
   */
  private dollyZoom(): void {

    if (!this.athlete.playable || disciplinePhasesManager.actualPhase === DisciplinePhases.finish) return

    if (!disciplinePhasesManager.phaseRunning.runStarted) return

    if (this.dollyZoomDefaultFov === undefined) {

      this.dollyZoomDefaultFov = cameraManager.getMainCamera().fov
      this.fovLerp.x = this.dollyZoomDefaultFov

    }

    const { maxFov } = cameraConfig.dollyZoom
    const percentSprintPart = this.athlete.speedBarManager.getPercentFromSprintPart()
    const fov = this.dollyZoomDefaultFov + (percentSprintPart * (maxFov - this.dollyZoomDefaultFov))

    this.fovVector.x = fov
    this.fovLerp.lerp(this.fovVector, 0.1)

    // kvoli zbytocnemu nastavovaniu
    if (this.lastFovLerpValue != this.fovLerp.x) {

      cameraManager.changeBaseRenderSettings(undefined, undefined, this.fovLerp.x)
      this.lastFovLerpValue = this.fovLerp.x


    }

  }

  /**
   * Zistovanie, ci sme v zakrute a treba naklonit
   */
  private checkLeanOn(): void {

    this.leanOnActive = false
    const { leanOnPercent } = pathsConfig
    if (
      (
        this.actualPercent > leanOnPercent[0].start[this.pathIndex - 1] &&
         this.actualPercent < leanOnPercent[0].end[this.pathIndex - 1]
      ) ||
      (
        this.actualPercent > leanOnPercent[1].start[this.pathIndex - 1] &&
         this.actualPercent < leanOnPercent[1].end[this.pathIndex - 1]
      )
    ) {

      this.leanOnActive = true

    }

  }

  /**
   * Ci uz je hrac v cieli
   * @returns boolean
   */
  public isFinishEnabled(): boolean {

    return this.lap >= gameConfig.numberOfLaps &&
      this.actualPercent + this.lungeOffset > pathsConfig.positionsFinish[this.pathIndex - 1]

  }

  /**
   * Vyratanie dlzky ciary a jedneho metra na ciare
   */
  private calculateLineInfo(): void {

    this.totalPathLength = this.playerPath?.getLength() || 0

    // takisto este potrebujeme 1m kolko je %
    this.oneMeterInPercent = 1 / this.totalPathLength
    this.lungeOffset = lungeConfig.lungeOffsetMin * this.oneMeterInPercent

  }

  /**
   * nastavenie object3d na zaciatku
   */
  private setupObject3d(): void {

    if (this.playerPath === undefined) return

    const point = this.getNewPoint(this.lungeOffset - lungeConfig.lungeOffsetMin * this.oneMeterInPercent)

    const pointToLookAt = this.getNewPoint(this.lungeOffset -
        lungeConfig.lungeOffsetMin * this.oneMeterInPercent +
        0.01)
    if (point) {

      this.object3d.position.set(point.x, point.y + gameConfig.yPlayerCorrection, point.z)
      if (pointToLookAt) pointToLookAt.y += gameConfig.yPlayerCorrection

    }

    if (pointToLookAt) {

      this.object3d.lookAt(pointToLookAt)
      this.lookingAt = pointToLookAt

    }

  }

  /**
   * Vypocitanie noveho bodu pre hraca
   * @returns novy bod alebo undefined ak sa nieco pokazilo
   */
  private getNewPoint(offset = 0): THREE.Vector3 | undefined {

    let point = this.playerPath?.getPointAt(this.actualPercent + offset)
    if (this.actualPercent + offset > 1) {

      point = this.playerPath?.getPointAt(this.actualPercent - 1 + offset)

    }
    if (!point) return

    if (this.changePathTransitionFrames > 0) {

      const { framesToChange } = pathsConfig.changePathConfig

      this.changePathTransitionFrames -= 1

      let oldPoint = this.oldPlayerPath?.getPointAt(this.oldActualPercent + offset)
      if (this.oldActualPercent + offset > 1) {

        oldPoint = this.oldPlayerPath?.getPointAt(this.oldActualPercent - 1 + offset)

      }
      if (!oldPoint) return

      this.helperVectorDirection.set(0, 0, 0)
      this.helperVectorDirection.subVectors( point, oldPoint )
      const divider = (framesToChange - this.changePathTransitionFrames) / framesToChange
      const distance = this.helperVectorDirection.multiplyScalar(divider)

      point = oldPoint.add(distance)

    } else {

      if (this.athlete.playable && this.athlete.isChangingPath) {

        tutorialFlow.eventActionTrigger(TutorialEventType.changePathDone)

      }
      this.athlete.isChangingPath = false
      if (this.athlete.playable) {

        disciplinePhasesManager.phaseRunning.switchLaneBlocker = false

      }

    }

    return point

  }

  /** reset */
  public reset(): void {

    this.setActualPercentOnStartIntro()
    this.finishPhaseStarted = false
    this.calculateLineInfo()
    this.setupObject3d()
    this.actualIdealOffset = gameConfig.cameraConfig.idealOffset.clone()
    this.lap = 0
    this.lungeOffset = 0
    this.playedAudioPreFinalLap = false
    this.playedAudioFinalLap = false
    this.togoTypeLast = -1
    this.percentOfActualSection = 0
    this.actualSectionIndex = 0
    this.leanOnActive = false
    this.changePathTransitionFrames = 0

    // reset dolly zoom veci
    this.dollyZoomDefaultFov = undefined
    this.fovLerp.set(0, 0, 0)
    this.fovVector.set(0, 0, 0)

  }

}
